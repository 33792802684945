<template>
  <div>
    <section>
      <b-modal :active="isComponentModalActive" @close="closeModal">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p v-if="contactUpdating" class="modal-card-title">
              Atualizar informações do contato
            </p>
            <p v-else class="modal-card-title">
              Insira o contato a ser inserido
            </p>
          </header>
          <section class="modal-card-body">
            <b-tabs type="is-boxed">
              <b-tab-item label="Dados" icon="account-box">
                <div class="tab-content">
                  <div class="columns is-centered">
                    <div class="column is-one-quarter">
                      <b-image v-if="imageSrc" :src="imageSrc" alt="The Buefy Logo" ratio="300by300"></b-image>
                    </div>
                  </div>
                  <b-field grouped>
                    <b-field label="Nome" expanded>
                      <b-input type="text" v-model="name" placeholder="Nome" required>
                      </b-input>
                    </b-field>
                    <b-field class="phone-input" label="Telefone (whatsapp)" :message="invalidWppNumber.message"
                      :type="invalidWppNumber.style" expanded>
                      <b-input v-if="contactUpdating" type="text" v-model="phone"
                        placeholder="Número de telefone do whatsapp" disabled required>
                      </b-input>
                      <VuePhoneNumberInput v-else v-on:update="phoneUpdateHandler" v-model="phone"
                        default-country-code="BR" :translations="translations"
                        :valid-color="invalidWppNumber.validColor" />
                    </b-field>
                  </b-field>
                  <b-field grouped>
                    <b-field label="CPF/CNPJ" expanded>
                      <b-input type="text" v-model="cpf_cnpj" placeholder="CPF/CNPJ">
                      </b-input>
                    </b-field>
                    <b-field label="Data de aniversário" expanded>
                      <b-input type="date" v-model="birthday" placeholder="Data de aniversário">
                      </b-input>
                    </b-field>
                  </b-field>
                  <b-field label="Tipo de contato">
                    <b-select placeholder="" v-model="contact_type" expanded>
                      <option value="" key="">

                      </option>
                      <option value="1" key="1">
                        Cliente
                      </option>
                      <option value="2" key="2">
                        Produtor
                      </option>
                      <option value="3" key="3">
                        Corretor
                      </option>
                    </b-select>
                  </b-field>
                  <b-field grouped>
                    <b-field label="Email" expanded>
                      <b-input type="text" v-model="email" placeholder="Email">
                      </b-input>
                    </b-field>
                    <b-field label="Instagram" expanded>
                      <b-input type="text" v-model="instagram_profile" placeholder="Instagram">
                      </b-input>
                    </b-field>
                  </b-field>
                  <b-field grouped>
                    <b-field label="Endereço" expanded>
                      <b-input type="text" v-model="address" placeholder="Endereço">
                      </b-input>
                    </b-field>
                    <b-field label="Complemento">
                      <b-input type="text" v-model="address_2" placeholder="Complemento">
                      </b-input>
                    </b-field>
                  </b-field>
                  <b-field grouped>
                    <b-field label="Bairro" expanded>
                      <b-input type="text" v-model="neighborhood" placeholder="Bairro">
                      </b-input>
                    </b-field>
                    <b-field label="Cidade" expanded>
                      <b-input type="text" v-model="city" placeholder="Cidade">
                      </b-input>
                    </b-field>
                    <b-field label="Estado">
                      <b-input type="text" v-model="district" placeholder="Estado">
                      </b-input>
                    </b-field>
                  </b-field>
                  <b-field label="Informações adicionais">
                    <editor api-key="no-api-key" v-model="additional_info" output-format="text" :init="{
                      height: 200,
                      menubar: false,
                      plugins: ['emoticons'],
                      toolbar: 'emoticons',
                      branding: false,
                      statusbar: false,
                    }" />
                  </b-field>
                </div>
              </b-tab-item>
              <b-tab-item v-if="canLink" label="Encarteiramentos" icon="wallet">
                <div class="tab-content">
                  <p v-if="contactAttendants.length === 0 && links.length === 0" class="no-links">
                    Este contato não está vinculado a nenhum atendente.
                  </p>

                  <div>
                    <ContactAttendantLinkList :attendants="attendants" :departments="departments"
                      :contactAttendants="contactAttendants" @addLinks="handleNewLink" />
                  </div>
                </div>
              </b-tab-item>
              <b-tab-item label="Tags" icon="tag-multiple" style="border: red !important">
                <div class="tab-content">
                  <b-field label="Tags do contato">
                    <multiselect v-model="selectedTags" :multiple="true" :options="tags" label="title" track-by="id"
                      select-label="" deselect-label="">
                      <template slot="placeholder">Tags do contato</template>
                    </multiselect>
                  </b-field>
                </div>
              </b-tab-item>
            </b-tabs>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="closeModal">
              Fechar
            </button>
            <button v-if="!!contactUpdating && contactUpdating.id" class="button is-primary" @click="updateContact">
              Atualizar
            </button>
            <button v-else class="button is-primary" @click="addContact">
              Gravar
            </button>
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input'
import ContactAttendantLinkList from '@/components/contacts/ContactAttendantLinkList'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import multiselect from 'vue-multiselect'
import { get, post } from '../../utils/api'
import Editor from '@tinymce/tinymce-vue'
import { mapActions, mapGetters } from 'vuex'
import { API_TYPE } from '../../utils/constants'

export default {
  name: 'ModalInsertContact',
  props: {
    isComponentModalActive: {
      type: Boolean,
      required: true
    },
    contactUpdating: {
      type: Object,
      required: false
    },
    departments: {
      type: Array,
      required: false
    },
    attendants: {
      type: Array,
      required: false
    },
    contactAttendants: {
      type: Array,
      required: false
    },
    canLink: {
      type: Boolean,
      required: true
    },
    contactTags: {
      type: Array,
      required: true
    },
    tags: {
      type: Array,
      required: true
    }
  },
  components: {
    VuePhoneNumberInput,
    ContactAttendantLinkList,
    multiselect,
    Editor
  },
  async created() {
    if (this.contactUpdating) {
      this.name = this.contactUpdating.name
      this.phone = this.contactUpdating.phone

      this.address = this.contactUpdating.address
      this.address_2 = this.contactUpdating.address_2
      this.neighborhood = this.contactUpdating.neighborhood
      this.city = this.contactUpdating.city
      this.district = this.contactUpdating.district
      this.contact_type = this.contactUpdating.contact_type
      this.additional_info = this.contactUpdating.additional_info

      this.email = this.contactUpdating.email
      this.instagram_profile = this.contactUpdating.instagram_profile

      if (this.contactUpdating.birthday) {
        const birthday = this.contactUpdating.birthday.split('T')
        if (birthday) {
          this.birthday = birthday[0]
        }
      }
      this.cpf_cnpj = this.contactUpdating.cpf_cnpj

      if (this.contactUpdating.id) {
        this.imageSrc = await this.getImage(this.contactUpdating.id)
      }

      this.links = JSON.parse(JSON.stringify(this.contactAttendants))

      if (this.contactTags && this.contactTags.length > 0) {
        this.selectedTags = this.tags.filter((tag) => {
          return this.contactTags.some(contactTag => {
            return tag.id === contactTag.tag_id
          })
        })
      }
    }
    this.fetchSettings();
    this.fetchWarnings();
  },
  watch: {
    contactTags(newTags, oldTags) {
      this.selectedTags = this.tags.filter((tag) => {
        return newTags.some(contactTag => {
          return tag.id === contactTag.tag_id
        })
      })
    },
    async phone (newPhone, oldPhone) {
      this.invalidWppNumber.message = ''
      this.invalidWppNumber.style = ''
      this.invalidWppNumber.validColor = 'yellowgreen'
      const phone = newPhone ? newPhone.replace(/\+|-|\(|\) /g, '') : newPhone
      if (phone && phone.length >= 10) {
        if (!this.allSettingsEnabled.find(settings => settings.api_type === API_TYPE.WWEBJS)) {
          console.warn('Não há nenhuma conta do whatsapp cadastrada!')
          return
        }
        const phones = []
        this.allSettingsEnabled.map(setting => {
          if (setting.api_type === API_TYPE.WWEBJS && setting.enabled === true) {
            phones.push(setting.phone)
          }
        })
        if (!this.getWarnings.phonesConnected.some(phone => phones.includes(phone))) {
          console.warn('Nenhuma conta do whatsapp conectada!')
          return
        }
        try {
          this.validationSuccess = true
          this.invalidWppNumber.message = 'Validando número...'
          this.invalidWppNumber.style = 'is-warning'
          this.invalidWppNumber.validColor = 'yellow'
          const validatePhone = await post('messages/validate_number_have_whatsapp', { phone_number: this.countryCode + phone })

          if (!validatePhone.data.isRegistered) {
            this.invalidWppNumber.message = 'Número não cadastrado no whatsapp!'
            this.invalidWppNumber.style = 'is-danger'
            this.invalidWppNumber.validColor = 'red'
          } else {
            this.invalidWppNumber.message = ''
            this.invalidWppNumber.style = ''
            this.invalidWppNumber.validColor = 'yellowgreen'
          }
        } catch (error) {
          if (error.isAxiosError && error.response.data.message.includes('não há instâncias do whatsapp conectadas')) {
            this.validationSuccess = false
            this.invalidWppNumber.message = 'Nenhuma conta do whatsapp conectada!'
            this.invalidWppNumber.style = 'is-danger'
            this.invalidWppNumber.validColor = 'red'
          } else {
            this.invalidWppNumber.message = 'Ocorreu um erro ao validar o número, aguarde alguns instantes e tente novamente'
            this.invalidWppNumber.style = 'is-danger'
            this.invalidWppNumber.validColor = 'red'
          }
        }
      }
    }
  },
  data() {
    return {
      name: '',
      phone: null,
      address: null,
      address_2: null,
      neighborhood: null,
      city: null,
      district: null,
      birthday: null,
      cpf_cnpj: null,
      email: null,
      instagram_profile: null,
      contact_type: null,
      validPhone: '',
      countryCode: '',
      translations: {
        countrySelectorLabel: 'Código país',
        countrySelectorError: 'Selecione um país',
        phoneNumberLabel: 'Número de telefone',
        example: 'Exemplo :'
      },
      imageSrc: '',
      department_id: '',
      attendant_id: '',
      links: [],
      selectedTags: [],
      additional_info: '',
      invalidWppNumber: {
        message: '',
        style: '',
        validColor: 'yellowgreen'
      },
      validationSuccess: true
    }
  },
  methods: {
    ...mapActions(['fetchSettings', 'fetchWarnings']),
    async getImage(id) {
      if (!id) return
      const contactImage = await get(`contacts/image/${id}`)
      return contactImage ? `data:image/png;base64,${contactImage.data}` : ''
    },
    getContactType(contactType) {
      switch (contactType) {
        case '1':
          return 'Cliente'
        case '2':
          return 'Produtor'
        case '3':
          return 'Corretor'
        default:
          break
      }
    },
    addContact() {
      if (this.links.length > 0) {
        this.links.forEach(link => {
          if (!link.department_id || link.attendant_id === '') {
            this.$buefy.dialog.alert({
              title: 'Encarteiramento incompleto',
              message:
                'É necessário selecionar departamento e atendente para todo encarteiramento',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
          }
        })
      }
      if (this.validationSuccess) {
        this.addContactEmitter()
      } else {
        this.$buefy.dialog.confirm({
          title: 'Falha na validação',
          message: 'Não foi possível validar se o número informado possui conta WhatsApp. Deseja adicionar o contato mesmo assim?',
          confirmText: 'Sim',
          cancelText: 'Não',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true,
          onConfirm: () => {
            this.addContactEmitter()
          }
        })
      }
    },
    addContactEmitter () {
      this.$emit('addContact', {
        info: {
          name: this.name,
          phone: this.countryCode
            ? `${this.countryCode}${this.validPhone}`
            : this.phone,
          address: this.address,
          address_2: this.address_2,
          neighborhood: this.neighborhood,
          city: this.city,
          district: this.district,
          birthday: this.birthday,
          cpf_cnpj: this.cpf_cnpj,
          email: this.email,
          instagram_profile: this.instagram_profile,
          contact_type: this.contact_type,
          additional_info: this.additional_info,
          duplicate: false
        },
        links: {
          info: this.links,
          contact_id: null,
          contact_name: this.name,
          contact_phone: this.countryCode
            ? `${this.countryCode}${this.validPhone}`
            : this.phone
        },
        tags: {
          info: this.selectedTags,
          contact_id: null,
          contact_name: this.name,
          contact_phone: this.countryCode
            ? `${this.countryCode}${this.validPhone}`
            : this.phone
        }
      })
    },
    closeModal() {
      this.$emit('closeModal')
    },
    phoneUpdateHandler(phoneObj) {
      this.countryCode = phoneObj.countryCallingCode
      this.validPhone = phoneObj.nationalNumber
    },
    updateContact() {
      if (this.name && this.name.length >= 0 && this.name.length <= 2) {
        this.$buefy.dialog.alert({
          title: 'Nome muito curto',
          message:
            'O <b>nome</b> informado é inválido. O novo nome deve ter no mínimo 2 caracteres!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })

        return
      }

      if (this.links.length > 0) {
        this.links.forEach(link => {
          if (!link.department_id || link.attendant_id === '') {
            this.$buefy.dialog.alert({
              title: 'Encarteiramento incompleto',
              message:
                'É necessário selecionar departamento e atendente para todo encarteiramento',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
          }
        })
      }

      this.emitUpdateContact({
        info: {
          id: this.contactUpdating.id,
          name: this.name,
          address: this.address,
          address_2: this.address_2,
          neighborhood: this.neighborhood,
          city: this.city,
          district: this.district,
          birthday: this.birthday,
          cpf_cnpj: this.cpf_cnpj,
          email: this.email,
          instagram_profile: this.instagram_profile,
          contact_type: this.contact_type,
          additional_info: this.additional_info
        },
        links: {
          info: this.links,
          contact_id: this.contactUpdating.id
        },
        tags: {
          info: this.selectedTags,
          contact_id: this.contactUpdating.id
        }
      })
    },
    emitUpdateContact(contact) {
      this.$emit('updateContact', contact)
    },
    handleNewLink(links) {
      this.links = links
    }
  },
  computed: {
    ...mapGetters(['allSettingsEnabled', 'getWarnings']),
  }
}
</script>

<style scoped>
.no-links {
  margin: 10px 0px 50px;
}

.modal .animation-content .modal-card {
  overflow: visible !important;
}

.modal-card-body {
  overflow: visible !important;
}

.tab-content {
  min-height: 55vh;
}

.modal-card-foot {
  justify-content: flex-end !important;
}
</style>
